import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { IProduct } from 'src/core/declarations/app';
import { QueryKeys } from 'src/core/declarations/enum';
import { getButtonAnimationContent } from 'src/crud/crud';
import ArComponent from './ArComponent';
import LoadingScreen from './LoadingScreen';
import { Fade } from '@mui/material';
import { useAppContext } from 'src/core/store';
import { LoadingBox } from 'src/components';

const ARPage = () => {
  const { i18n } = useTranslation();
  const { appLoadingStateEvent, arResourcesLoadEvent } = useAppContext();

  const [modelLoading, setModelLoading] = useState(true);

  const queryClient = useQueryClient();
  const productData = queryClient.getQueryData<IProduct>(QueryKeys.product);

  const { isLoading } = useQuery(QueryKeys.buttonAnimationContent, () => {
    return getButtonAnimationContent((productData?.id as string), i18n.language)
  }, {
    enabled: !!productData && !!productData.id
  });

  useEffect(() => {
    // disable app loading page
    if (appLoadingStateEvent && appLoadingStateEvent.getValue() === true) {
      appLoadingStateEvent.next(false);
    }
  })

  useEffect(() => {
    if (arResourcesLoadEvent) {
      const subscription = arResourcesLoadEvent.subscribe(() => {
        setModelLoading(false);
      });

      return () => {
        subscription.unsubscribe();
      }
    }
  }, [arResourcesLoadEvent])

  /**
   * Display loading screen with data from server
   * Then loading 3D Object in the background
  */
  if (isLoading) {
    return <LoadingBox sx={{ height: '100%' }} />;
  }

  return (
    <>
      <ArComponent product={productData as IProduct} />
      <Fade in={modelLoading}>
        <LoadingScreen product={productData as IProduct} />
      </Fade>
    </>
  )
}

export default ARPage;
