import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './routes/AppRouter';
import { InitialPage } from './containers';
import { useLanguage } from './core/i18n';
import { LoadingBox } from './components';
import { Route, BrowserRouter, Switch, withRouter } from 'react-router-dom';
import { AppPages } from './routes/routeMap';
import { useTrackBrowserHeight } from './core/helpers';
import { AppStore, Context, useAppContext } from 'src/core/store';
import RegisterAframe from './A-Frame/RegisterAframe';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
});

const appStore = new AppStore();


const Wrapper = withRouter(() => {
  const { i18nLoading } = useLanguage();
  const { appLoadingStateEvent, arResourcesLoadEvent } = useAppContext();
  const [arResourceLoaded, setArResourceLoaded] = useState(true);
  const [isAppLoading, setIsAppLoading] = useState(appLoadingStateEvent.getValue());

  useEffect(() => {
    window.addEventListener('load', () => {
      // wait until all of the 8thwall stuffs are available
      setArResourceLoaded(true);
    })

    return () => { }
  }, [arResourcesLoadEvent])

  useEffect(() => {
    if (appLoadingStateEvent) {
      const subscription = appLoadingStateEvent.subscribe(isAppLoading => {
        setIsAppLoading(isAppLoading);
      });

      return () => {
        subscription.unsubscribe();
      }
    }
  }, [appLoadingStateEvent])

  if (i18nLoading || !arResourceLoaded
  ) {
    return <LoadingBox sx={{ height: '100%' }} />;
  }

  return (
    <>
      <Switch>
        <Route path={AppPages.InitialPage} component={InitialPage} />
        <AppRouter />
      </Switch>

      {isAppLoading && (<LoadingBox sx={{
        height: '100%',
        zIndex: 1060,
        position: 'fixed',
        backgroundColor: '#fff',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      }} />)}

      {/* register aframe component once */}
      <RegisterAframe />
    </>
  )
});


function App() {
  // browser resize event should be bound here to avoid muiltiple executions
  useTrackBrowserHeight();

  return (
    <Context.Provider value={appStore}>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<LoadingBox sx={{ height: '100%' }} />}>
          <BrowserRouter>
            <Wrapper />
          </BrowserRouter>
        </React.Suspense>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      </QueryClientProvider>
    </Context.Provider>

  );
}

export default App;
