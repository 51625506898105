import React, { memo, useEffect } from 'react';
import { modelRef } from 'src/core/declarations/enum';
import { useAppContext } from 'src/core/store';
import { cubeEnvMapComponent } from './cubemap-static';

declare let AFRAME: any;

const RegisterAframe = memo(() => {
  const { aFrameModelLoadedEvent, arResourcesLoadEvent } = useAppContext();

  useEffect(() => {

    const realityReadyHandler = () => {
      arResourcesLoadEvent.next({});
    }

    AFRAME.registerComponent(modelRef, {
      init: function () {
        let scene = this.el.sceneEl
        scene.addEventListener('realityready', realityReadyHandler);
        // Wait for model to load.
        this.el.addEventListener('model-loaded', () => {

          aFrameModelLoadedEvent.next(this.el);
        });
      },
      remove: function () {
        let scene = this.el.sceneEl
        scene.removeEventListener('realityready', realityReadyHandler);
      }
    });

    AFRAME.registerComponent('cubemap-static', cubeEnvMapComponent);
  })

  return (<></>)
})

export default RegisterAframe;
