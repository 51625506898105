import { createTheme, ThemeOptions } from "@mui/material";
import { deepmerge } from '@mui/utils';

const isMobileOrMac = typeof navigator !== 'undefined' && /iPad|iPhone|iPod|Android|Mac OS/gi.test(navigator.userAgent);

const fontHelveticaNeueLTPro = '"Helvetica Neue LT Pro", "Roboto", "Helvetica", "Arial", sans-serif';

const coreTheme: ThemeOptions = {
  background: {
    pink: '#F47E7E',
    cyan: '#00A0B6',
    black: '#000'
  },
  // FIXME custom break points should be defined differently
  // breakpoints: {
  //   values: {
  //     mobileSm: 640,
  //     mobileMd: 700,
  //     mobileLg: 800
  //   }
  // },
  palette: {
    text: {
      primary: '#fff',
      secondary: '#000'
    },
    // white
    white: {
      main: '#fff',
      light: '#fff',
      dark: '#cccccc',
      contrastText: '#000000'
    },
    // black
    black: {
      main: '#000',
      light: '#2c2c2c',
      dark: '#000',
      contrastText: '#fff'
    }
  },
  typography: {
    h2: {
      color: '#fff',
      fontSize: '3.125rem',
      fontWeight: 'bold',
      fontFamily: fontHelveticaNeueLTPro,
      lineHeight: 1.1,
    },
    h3: {
      fontSize: '1.875rem',
    },
    h4: {
      fontSize: '1.625rem',
      color: '#707070',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      fontFamily: fontHelveticaNeueLTPro,
      color: '#fff'
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    body1: {
      color: '#fff',
      fontSize: '1rem',
      fontFamily: fontHelveticaNeueLTPro,
    },
    body2: {
      color: '#000',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontFamily: fontHelveticaNeueLTPro
    },
    caption: {
      fontFamily: fontHelveticaNeueLTPro,
      fontSize: '.5rem',
      lineHeight: 1,
      color: '#767676'
    },
    fontFamily: fontHelveticaNeueLTPro,
    fontSize: 16
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000'
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000'
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#000'
        },
        input: {
          color: '#000'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          fontSize: '22px',
          color: '#EA9D27',
          '& .MuiRating-iconEmpty': {
            visibility: 'hidden',
            display: 'none'
          }
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#000'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&:not(.MuiDialogTitle-root)': {
            ...(isMobileOrMac
              ? {
                transform: 'translateY(calc(.15em))'
              }
              : {})
          },
        },
      }
    },
    MuiButton: {
      variants: [
        {
          props: { color: 'white', variant: 'contained' },
          style: {
            color: '#000',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }
        },
        {
          props: { color: 'black', variant: 'outlined' },
          style: {
            color: '#fff',
            backgroundColor: '#000',
            borderColor: '#fff',
            '&:hover': {
              backgroundColor: '#000',
            }
          }
        },
        {
          props: { color: 'white', variant: 'outlined' },
          style: {
            color: '#000',
            backgroundColor: '#fff',
            borderColor: '#000',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }
        },
        {
          props: { size: 'extraLarge', variant: 'contained' },
          style: {
            fontSize: '1.875rem',
            width: '200px',
            padding: '1.25rem',
            lineHeight: 1,
            borderRadius: '2.1875rem/50%',
          }
        }
      ],
      styleOverrides: {
        root: {
          outline: 'none',
          borderRadius: '1.25rem/50%',
          textTransform: 'none',
          fontWeight: 'normal',
          // helvetica neue Pro is not aligned on iOS & Android devices
          // need to translate the text down to make it looks like it is aligned
          '& span.button-text': {
            ...(isMobileOrMac
              ? {
                transform: 'translateY(calc(.15em))'
              }
              : {})
          }
        }
      }
    }
  }
}

const createAppTheme = (palette?: ThemeOptions['palette']) => {

  return createTheme(deepmerge(
    // set core settings
    coreTheme,
    // set brand settings
    {
      palette: {
        ...palette
      }
    } as ThemeOptions
  ))
}

export default createAppTheme;
