import { Grid, InputAdornment, TextField, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppGrid, AppButton } from 'src/components';
import { AccountCircle, VpnKey } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useQueryClient } from 'react-query';
import { IProduct, IQRCodeData } from 'src/core/declarations/app';
import { QueryKeys } from 'src/core/declarations/enum';
import { useHistory } from 'react-router';
import { useAppContext } from 'src/core/store';

const LoginPage = () => {

  const queryClient = useQueryClient();

  const qrCodeData = queryClient.getQueryData(QueryKeys.qrCode) as IQRCodeData;
  const productData = queryClient.getQueryData(QueryKeys.product) as IProduct;
  const history = useHistory();

  const { appCredential } = useAppContext();

  useEffect(() => {
    if (!qrCodeData) {
      history.push('/initialize');
    }
  });

  const [credential, setUserCredential] = useState({
    username: '',
    password: ''
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCredential({ ...credential, username: event.target.value });
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCredential({ ...credential, password: event.target.value });
  };

  const handleSubmit = () => {
    appCredential.next(credential);
    if (!productData) {
      history.push('/');
    } else {
      history.push('/ar-page');
    }
  }

  return (
    <AppGrid sx={{
      gridTemplateRows: 'auto',
      flexGrow: 1,
      p: 2
    }}>
      {qrCodeData && (
        <Grid>
          <Grid sx={{ mb: 1 }}>
            <TextField
              fullWidth
              id="username"
              label="Username"
              value={credential.username}
              onChange={handleUserName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Grid>

          <Grid sx={{ mb: 3 }}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              value={credential.password}
              type={showPassword ? 'text' : 'password'}
              onChange={handlePassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword
                        ? (<VisibilityOffIcon />)
                        : (<VisibilityIcon />)}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              variant="standard"
            />
          </Grid>

          <Grid>
            <AppButton
              onClick={() => handleSubmit()}
              fullWidth
              variant="contained"
              color="primary">Sign in</AppButton>
          </Grid>

        </Grid>
      )}

    </AppGrid>

  )
}

export default LoginPage;
