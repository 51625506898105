import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import { useAppContext } from 'src/core/store';
import { filter } from 'rxjs';

interface AppHeaderProps {
  brandName?: string;
  logo?: string;
}

const AppHeader = ({ brandName, logo }: AppHeaderProps) => {
  const { initialPageUrl } = useAppContext();
  const [shareLink, setShareLink] = useState('');

  const shareLinkHandle = useCallback(() => {
    if (navigator.share && shareLink) {
      navigator.share({
        title: `Try shaving augmented experience with Web AR at ${shareLink}`,
        text: `Try shaving augmented experience with Web AR at ${shareLink}`,
        url: shareLink
      }).then(() => {
      }).catch(err => {

      })
    }
  }, [shareLink]);

  useEffect(() => {
    if (!!initialPageUrl) {
      const subscription = initialPageUrl
        .pipe(filter(link => !!link))
        .subscribe(link => {
          setShareLink(link);
        })

      return () => { subscription.unsubscribe(); }
    }
  }, [initialPageUrl])

  return (
    <AppBar sx={{
      zIndex: (theme) => theme.zIndex.modal + 1
    }}>
      <Toolbar>
        <Link to="/" style={{ height: '24px' }}>
          <img style={{ height: '100%', width: 'auto' }} src={logo} alt={brandName} />
        </Link>
        <IconButton onClick={() => shareLinkHandle()} sx={{ marginLeft: 'auto', color: '#fff' }}>
          <ShareIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader;
