import React, { useMemo, forwardRef, ForwardedRef } from 'react';
import { Grid, GridTypeMap, Rating, Toolbar, Typography, LinearProgress, Box, Slide } from '@mui/material';
import { AppGrid, LazyImage } from 'src/components';
import { makeStyles } from '@mui/styles';
import { IProduct } from 'src/core/declarations/app';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

interface LoadingScreenProps {
  product: IProduct;
}

// TODO remove if not used anymore
// const ProductTheme = memo(() => (
//   <svg
//     style={{
//       position: 'absolute',
//       left: '-14px',
//       bottom: '10px',
//       zIndex: -1
//     }}
//     xmlns="http://www.w3.org/2000/svg"
//     width="28.12"
//     height="128.056"
//     viewBox="0 0 28.12 128.056">
//     <g id="Group_26" data-name="Group 26" transform="translate(0 0)">
//       <path id="Path_5" data-name="Path 5" d="M289.392,598.024V497.516c15.53,0,28.12,12.334,28.12,27.548V625.572c-15.531,0-28.12-12.333-28.12-27.548" transform="translate(-289.392 -497.516)" fill="#0092a9" />
//       <path id="Path_6" data-name="Path 6" d="M289.392,625.948l28.12-72.545v99.935c-15.531,0-28.12-12.263-28.12-27.391" transform="translate(-289.392 -525.283)" fill="#00a0b6" />
//     </g>
//   </svg>
// ));

interface IStyledRatingsProps {
  averageRatings: number;
  displayNumber?: boolean;
}

const StyledRatings = ({ averageRatings, displayNumber = true, sx }: IStyledRatingsProps & DefaultComponentProps<GridTypeMap>) => (
  <Grid sx={{
    display: 'inline-flex',
    alignItems: 'center',
    justifyItems: 'center',
    p: '5px',
    borderRadius: `16px/50%`,
    backgroundColor: 'rgba(48, 35, 35, .5)',
    ...sx
  }}>
    <Rating
      readOnly
      defaultValue={averageRatings}
      size="medium" />
    {displayNumber && (<Typography variant="h6" sx={{ lineHeight: 1, px: 1, fontWeight: 'bold' }}>{averageRatings}</Typography>)}
  </Grid>
)

const useStyles = makeStyles(() => ({
  contentBlock1: {
    marginTop: 'auto'
  },
  contentBlock2: {
    marginLeft: 'auto'
  },
  contentBlock3: {},
}));

const LoadingScreen = forwardRef(({ product, ...props }: LoadingScreenProps & DefaultComponentProps<GridTypeMap>, ref: ForwardedRef<HTMLDivElement>) => {
  const classes = useStyles();
  const blockClass = (blockIndex: number) => {
    if (blockIndex === 0) return classes.contentBlock1;
    if (blockIndex === 1) return classes.contentBlock2;
    if (blockIndex === 2) return classes.contentBlock3;
  }

  const averageRatings = useMemo(() => {
    const _averageRatings = product.ratings.reduce((a: number, b: number) => a + b, 0) / product.ratings.length;
    return Math.floor(_averageRatings * 100) / 100;
  }, [product.ratings])

  return (
    <AppGrid ref={ref} {...props} sx={{
      gridTemplateRows: 'auto auto 1fr',
      flexGrow: 1,
      bgcolor: '#d7dadc',
      position: 'fixed',
      px: 1,
      pb: 1,
      left: 0,
      right: 0,
      top: 0,
      bottom: '20px', // minus consent area
      zIndex: 900
    }}>
      <Toolbar />
      <Box sx={{
        ml: -1,
        mr: -1
      }}>
        <LinearProgress color="secondary" />
      </Box>
      <Grid sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto'
      }}>
        <Grid sx={{
          borderRadius: theme => theme.spacing(1),
          bgcolor: theme => theme.background.black,
          px: 2,
          py: 3,
          position: 'relative',
          mt: 4,
          ml: 'auto',
          maxWidth: '300px'
        }}>
          {/* <ProductTheme /> */}
          <Typography sx={{ mb: 2, fontWeight: 'bold' }} variant="h6">{product.name}</Typography>
          <Typography>{product.productClaim}</Typography>
        </Grid>

        {/* TODO Need review how to deal with empty data */}

        {product.ratings.length > 0 && (
          <Slide direction="left" in={true} timeout={{ appear: 300, enter: 500, exit: 1000 }}>
            <Grid sx={{ ml: 'auto', mt: 2 }}>
              <StyledRatings averageRatings={averageRatings} />
            </Grid>
          </Slide>
        )}

        {/* TODO Need review how to deal with empty data */}

        {product.comments.length > 0 ?
          product.comments.map(({ stars, comment }, idx: number) =>
          (
            <Slide direction={idx % 2 === 0 ? 'right' : 'left'} key={`comment-excerpt-${idx}`} in={true} timeout={{
              appear: 1000 + idx * 300,
              enter: 1300 + idx * 300,
              exit: 1500 + idx * 300
            }}>
              <Grid className={blockClass(idx)}>
                <StyledRatings averageRatings={stars} displayNumber={false} />
                <Typography sx={{
                  width: '130px',
                  color: theme => theme.palette.text.secondary,
                  fontWeight: 'bold'
                }}>{`${comment.substring(0, 20).trim()}${comment.length > 20 ? ' ...' : ''}`}</Typography>
              </Grid>
            </Slide>
          )
          ) : ""
        }
      </Grid>

      <LazyImage src={product.image} alt={product.imageCaption} styles={{
        objectPosition: 'center 22vh',
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        objectFit: 'cover',
        height: "100%",
        width: "70%",
        marginLeft: "9vh"
      }} />
    </AppGrid>
  )
});

export default LoadingScreen;
