import { Grid, Button, Modal, IconButton, Box, ButtonProps, Typography, List, ListItemButton } from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { InfoIcon, LanguageIcon } from 'src/components/icons';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useQueryClient } from 'react-query';
import { TabPanel } from 'src/components';
import { QueryKeys } from 'src/core/declarations/enum';
import { ISupportLanguage } from 'src/core/declarations/app';

interface IinfoMenuProps {
	open: boolean;
	onClose?: () => void;
	// check header component height to render modal correctly
	headlineHeight?: number;

}

const tabPanelStyle: React.CSSProperties = {
	display: 'grid',
	overflowY: 'hidden'
};

const InfoButton = ({ children, ...rest }: ButtonProps) => (<Button
	variant="text"
	{...rest}
	sx={{
		color: theme => theme.typography.body1.color,
		fontSize: theme => theme.typography.h5.fontSize,
		fontWeight: theme => theme.typography.h5.fontWeight,
		pr: 3
	}}
>	{children}</Button>)

const InfoMenu = memo(({ open, onClose, headlineHeight }: IinfoMenuProps) => {
	const { t, i18n } = useTranslation();
	const [tabPanel, setTabPanel] = useState(0);
	const queryClient = useQueryClient();

	const supportedLanguages = queryClient.getQueryData<ISupportLanguage[]>(QueryKeys.language);

	const handleClose = () => {
		if (onClose) onClose();
		setTabPanel(0);
	};

	const switchLanguageHandle = (lng: string) => {
		i18n.changeLanguage(lng, (err) => {
			// if no error refetch all available queries to get localized data
			if (!err) {
				queryClient.invalidateQueries({
					refetchActive: true,
					refetchInactive: true
				});
			}
		});

		handleClose();
	}

	return (
		<Modal
			open={open}
			BackdropProps={{
				sx: {
					background: 'transparent'
				}
			}}
			onClose={handleClose}
		>
			<Box sx={{
				position: 'absolute',
				display: 'grid',
				gridTemplateRows: '1fr auto',
				top: `${
					// header height
					56
					// product claim height
					+ (headlineHeight || 0)
					// margin top
					+ 20
					}px`,
				left: '20px',
				background: "rgba(0, 0, 0, 0.7)",
				padding: "10px 15px 15px",
				borderRadius: "5px",
				maxWidth: 'calc(100vw - 40px)',
				maxHeight: `calc(100% - ${headlineHeight || 0}px - 116px)` // minus header height, headline height, consent height, and padding
			}}>
				<Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
					{tabPanel === 0
						? (<InfoIcon />)
						: (<IconButton
							onClick={() => { setTabPanel(0) }}
							sx={{ color: '#fff', p: 0, }}
						><ArrowBackIosIcon sx={{ fontSize: '20px' }} /></IconButton>)}

					<IconButton sx={{ p: 0 }} onClick={handleClose}>
						<CloseIcon style={{ color: '#fff' }} />
					</IconButton>
				</Grid>
				<TabPanel value={tabPanel} index={0} style={{ ...tabPanelStyle }}>
					<Grid sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start'
					}}>
						<InfoButton
							onClick={() => { setTabPanel(1) }}
						>{t('InfoDialogHelpButtonText')}</InfoButton>
						<InfoButton
							onClick={() => { setTabPanel(2) }}
							endIcon={<LanguageIcon />}
						>{t('InfoDialogLanguageButtonText')}</InfoButton>
					</Grid>
				</TabPanel>

				<TabPanel value={tabPanel} index={1} style={{ ...tabPanelStyle, width: 'calc(100vw - 70px)' }}>
					<Typography variant="h5" sx={{ fontWeight: 700, mb: 3, mt: '6px' }}>{t('InfoDialogHowToTitle')}</Typography>
					<Typography sx={{ whiteSpace: 'pre-line', overflowY: 'auto' }}>{t('InfoDialogHowToText')}</Typography>
				</TabPanel>

				<TabPanel value={tabPanel} index={2} style={{ ...tabPanelStyle, width: 'calc(100vw - 70px)' }}>
					<Typography variant="h5" sx={{ fontWeight: 700, mb: 3, textDecoration: 'underline', mt: '6px' }}>{t('InfoDialogChooseLanguageButtonText')}</Typography>
					<List sx={{ overflowY: 'auto' }}>
						{supportedLanguages && supportedLanguages.map((lng, idx) => (
							<ListItemButton
								onClick={() => switchLanguageHandle(lng.code)}
								key={`lng-${idx}`}
								disableGutters
								sx={{
									fontSize: theme => theme.typography.h5.fontSize,
									fontWeight: theme => theme.typography.h5.fontWeight,
								}}
							>
								{lng.name}
							</ListItemButton >))}
					</List>

				</TabPanel>
			</Box>
		</Modal>
	)
});

export default InfoMenu;
