
import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { QueryKeys } from 'src/core/declarations/enum';
import { getHomePageContent } from 'src/crud/crud';
import { AppGrid, AppButton, LoadingBox } from 'src/components';
import { useQuery } from 'react-query';
import HomePageBackground from './HomePageBackground';
import { IHomePageContent } from 'src/core/declarations/app';

const HomePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [appLoading, setAppLoading] = useState(true);
  const [nextPage, setNextPage] = useState(false);
  const { isLoading, data } = useQuery<IHomePageContent | null>(QueryKeys.homePageContent, () => getHomePageContent());

  const onBackgroundDisplayHandle = useCallback(() => {
    setAppLoading(false);
  }, [])

  // temporary fix the background flickering issue caused by video component is disposed when switching pages
  // by putting a loading box when next page button is clicked
  useEffect(() => {
    if (nextPage) {
      history.push('/scan-page');
    }
  })

  if (isLoading || !data) {
    return (<LoadingBox />)
  }

  return (
    <>
      {!!data.backgroundVideo && (<LoadingBox sx={{
        zIndex: 9,
        height: '100%',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        visibility: appLoading || nextPage ? 'visible' : 'hidden'
      }}
      />)}

      <AppGrid sx={{
        gridTemplateRows: '1fr auto',
        flexGrow: 1,
        bgcolor: 'transparent',
        p: 2,
        position: 'relative'
      }}>
        <HomePageBackground source={data.backgroundVideo} onBackgroundDisplayable={onBackgroundDisplayHandle} />

        <Grid sx={{
          display: 'grid',
          alignContent: 'center'
        }}>
          <Typography variant="h4">{t("HomePageTitleSubtitle")}</Typography>
          <Typography variant="h2" sx={{ whiteSpace: 'pre-wrap' }}>{t("HomePageTitle")}</Typography>
        </Grid>

        <Grid sx={{
          textAlign: 'center',
          marginBottom: 16
        }}>
          <AppButton
            size="extraLarge"
            variant="contained"
            color="white"
            onClick={() => { setNextPage(true); }}
          >
            {t('HomePageButtonText')}
          </AppButton>
        </Grid>
      </AppGrid>
    </>
  )
}

export default HomePage;
