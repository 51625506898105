// // A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with
// // any components or primitives that should be registered, and any image targets that should be
// // loaded if something other than the automatically loaded set is wanted. Passing
// // DISABLE_IMAGE_TARGETS will prevent any image targets from loading, including ones that would
// // otherwise enabled automatically.
import React, { memo, useEffect, useMemo, useRef } from 'react';
import parse from 'html-react-parser';
import { Box } from '@mui/material';
import { filter, skip, Subject, take } from 'rxjs';
import { AFrameElement, IBeardStyle, IButtonContent } from 'src/core/declarations/app';
import { useAppContext } from 'src/core/store';
import { modelRef } from 'src/core/declarations/enum';
// declare const AFRAME: any;

const modelButtons: any[] = []; //keep track of buttons in the 3D model for enabling/disabling on tap
declare const THREE: any;
declare let XR8: any;

const sceneGenerator = (modelLink: string, beardStyle: IBeardStyle[], arModelOverlays: Pick<IButtonContent, 'buttonName' | 'arModelOverlay'>[]) => `
  <a-scene
    id='ascene' 
    xrextras-gesture-detector
    xrextras-loading
    xrextras-runtime-error
    renderer="colorManagement: true"
    iframe-inner
    xrweb>

    <a-entity id="lights">
      <a-entity light="type:directional; color:white; intensity:0.25f" position="0 5 -2"></a-entity>
      <a-entity light="type:directional; castShadow:true; color:white; intensity:0.25f" position="-5 10 7"></a-entity>

      <a-light type="ambient" intensity= "0.2"></a-light>

      <!-- front light right -->
      <a-entity light="type:point; color:white; intensity:0.15f" position="1 0.65 1"></a-entity>
      <!--<a-sphere color="white" radius="0.1" position="1 0.7 1"></a-sphere>-->

      <!-- front light left -->
      <a-entity light="type:point; color:white; intensity:0.15f" position="-1 0.65 1"></a-entity>
      <!--<a-sphere color="white" radius="0.1" position="1 0.7 1"></a-sphere>-->

      <!-- back light -->
      <a-entity light="type:point; color:white; intensity:0.35f" position="0 1 -1"></a-entity>
      <!--<a-sphere color="white" radius="0.1" position="0 1 -1"></a-sphere>-->

    </a-entity>

    <a-camera position="0 2 2" raycaster="objects: .cantap" cursor="fuse: false; rayOrigin: mouse;"></a-camera>

    <a-assets id="assetContainer" timeout="30000">
    <a-asset-item id="model" src="${modelLink}"></a-asset-item>

    ${arModelOverlays.map(({ buttonName, arModelOverlay }) => `
    <video
      id="overlayVideo${buttonName}"
      class="alpha-video"
      preload="auto"
      loop="true"
      src="${arModelOverlay}" 
      type="video/mp4"
      crossorigin="anonymous"
     >
    </video>
    `).join(' ')}

      <!--box mapping reflections-->
      <img id="posx" src="imgs/HDRs/boxmap/posx.png">
      <img id="posy" src="imgs/HDRs/boxmap/posy.png">
      <img id="posz" src="imgs/HDRs/boxmap/posz.png">
      <img id="negx" src="imgs/HDRs/boxmap/negx.png">
      <img id="negy" src="imgs/HDRs/boxmap/negy.png">
      <img id="negz" src="imgs/HDRs/boxmap/negz.png">
    </a-assets>

    <a-plane height="10" width="10" rotation="-90 0 0" position="0 -0.01 0" material="shader: shadow; opacity: 0.2" shadow></a-plane>

    <a-entity id="modelContainer" visible="true" xrextras-one-finger-rotate xrextras-pinch-scale>

    <!-- test code -->
    ${process.env.NODE_ENV === 'development' && `
      <!--<a-entity gltf-model="3DModels/S9Pro_sasha.glb" cubemap-static position="0 0 0" scale="10 10 10" shadow="receive: false"> </a-entity>-->
      <a-sphere id="probe" visible="false" color="white" radius="0.2" position="0.5 0 0" material="roughness: 0; metalness: 1">
      ></a-sphere> 
      `}
    
    ${arModelOverlays.length > 0 && (`
        <a-entity id="overlayVideoMesh" visible="false" 
          xrextras-play-video="${arModelOverlays.length > 0 ? `video: #overlayVideo${arModelOverlays[0].buttonName}` : ''}"
          material="shader: chromakey; src: ${arModelOverlays.length > 0 ? `#overlayVideo${arModelOverlays[0].buttonName}` : ''}; color: 0 0 0; side: double; depthTest: true;"
          geometry="primitive: plane; height: 2; width: 2;">
        ></a-entity>
      `)
  }

    </a-entity>

    <!-- face effects -->
    <xrextras-resource id="alpha-soft-eyes" src="imgs/beards/soft-eyes.png"></xrextras-resource>
    ${beardStyle.map(({ id, beardImage }, beardIdx) => `
      <xrextras-resource id="beard-${id}" src="${beardImage}"></xrextras-resource>
      <xrextras-basic-material id="paint-${id}" tex="#beard-${id}" alpha="#alpha-soft-eyes" opacity="0.9">
    `).join(' ')}
    </xrextras-basic-material>

  </a-scene>
`;

// Testing code
// ${beardStyle.map(({ id, beardImage }, beardIdx) => `<xrextras-resource id="beard${id}" src="${beardImage}"></xrextras-resource>`).join(' ')}
// ${nodeEnv === 'production'
// ? `<a-asset-item id="model" src="${modelLink}"></a-asset-item>`
// : '<a-asset-item id="model" src="3DModels/S9Pro_3.glb"></a-asset-item>'}

interface AFrameComponentProps {
  productLink: string;
  recenterEvent?: Subject<any>;
  onButtonClick?: Function;
  buttonsList?: IButtonContent[];
  buttonToggleEvent?: Subject<string>;
  beardStyleEvent?: Subject<boolean>;
  switchBeardStyleEvent?: Subject<string>;
  beardStyles?: IBeardStyle[];
}

function DisableButtons() {
  for (const element of modelButtons) {
    element.visible = false;
  }
}

function EnableButtons() {
  document.querySelector('#modelEntity')?.setAttribute('animation-mixer', 'clip: none'); //reset animations
  for (const element of modelButtons) {
    element.visible = true;
  }
}

//#endregion AR stuffs

const AScene = memo((props: AFrameComponentProps) => {
  const { productLink, recenterEvent, buttonsList, buttonToggleEvent, onButtonClick, beardStyleEvent, switchBeardStyleEvent, beardStyles = [] } = props;
  const arModelOverlays = useMemo(() => {
    const btnList = buttonsList ? buttonsList
      .filter(b => !!b.arModelOverlay)
      .map<Pick<IButtonContent, 'buttonName' | 'arModelOverlay'>>(({ buttonName, arModelOverlay }) => ({ buttonName, arModelOverlay })) : [];
    return btnList;
  }, [buttonsList]);
  const arTemplateMemo = useMemo(() => {
    return parse(sceneGenerator(productLink, beardStyles, arModelOverlays));
  }, [productLink, beardStyles, arModelOverlays])

  const aFrameComponentRef = useRef<AFrameElement | null>(null);
  const buttonHandleEventRef = useRef(new Subject<string>());
  const { aFrameModelLoadedEvent } = useAppContext();

  useEffect(() => {
    const aFrameComponent = document.querySelector('a-scene') as AFrameElement | null;


    aFrameComponentRef.current = aFrameComponent;
    if (aFrameComponent) {
      // prepare when entity is init

      const subscription = aFrameModelLoadedEvent
        .pipe(
          // take only one event
          take(1)
        ).subscribe(entityEl => {
          const modelMesh = entityEl.getObject3D('mesh');

          //get list of buttons from backend and match in 3D model
          let counter = 0; //keep a counter to mark each button with an ID
          if (buttonsList && buttonsList.length > 0) {
            buttonsList.forEach((obj, idx) => {

              modelMesh.traverse((child: { [key: string]: any }) => {
                if (child.name.includes(obj.buttonName)) {
                  //add new flat material for buttons that doesn't use lighting
                  var prevMaterial = child.material;
                  child.material = new THREE.MeshBasicMaterial();
                  THREE.MeshBasicMaterial.prototype.copy.call(child.material, prevMaterial);
                  child.castShadow = false;
                  modelButtons.push(child); //add to array 
                  const newEl = document.createElement('a-box') as AFrameElement;
                  newEl.setAttribute('color', 'red');
                  document.querySelector('#modelContainer')?.appendChild(newEl);
                  const target = new THREE.Vector3();
                  child.getWorldPosition(target);
                  newEl.setAttribute('position', target);
                  newEl.setAttribute('scale', { x: 0.2, y: 0.2, z: 0.2 });
                  newEl.setAttribute('transparency', true);
                  newEl.setAttribute('opacity', 0);
                  newEl.setAttribute('class', 'cantap');

                  counter = counter + 1;
                  newEl.setAttribute('id', 'guiButton' + counter.toString());
                  newEl.addEventListener('click', (e) => {
                    entityEl.setAttribute('animation-mixer', 'clip: ' + obj.buttonName);
                    if (obj.animationLooping)
                      entityEl.setAttribute("animation-mixer", "loop: true;");
                    else
                      entityEl.setAttribute("animation-mixer", "loop: once;");
                    console.log('guiButton' + counter.toString() + ' tapped')

                    if (obj.hasBeardStyles) {
                      if (beardStyleEvent) beardStyleEvent.next(true);
                    } else {
                      if (buttonClickHandle) {
                        buttonClickHandle(obj.buttonName);
                      }
                    }
                  })

                }
                if (child.name.includes('VideoPlane')) {
                  console.log('has video plane');
                  child.visible = false;
                  const pos = new THREE.Vector3();
                  child.getWorldPosition(pos);
                  var scale = new THREE.Vector3();
                  child.getWorldScale(scale);
                  document.querySelector('#overlayVideoMesh')?.setAttribute('position', pos);
                  document.querySelector('#overlayVideoMesh')?.setAttribute('scale', scale);
                  document.querySelector('#overlayVideoMesh')?.setAttribute('shadow', 'cast: false');
                  //console.log('position: ' + pos.x + '  ' + pos.y + '  ' + pos.z);
                  //console.log('scale: ' + scale.x + '  ' +  scale.y + '  ' +  scale.z);
                }
              })
            })
          }
        })

      // bind entity to ascene
      const modelContainer = document.querySelector('#modelContainer');
      const entity = document.createElement('a-entity') as AFrameElement;
      entity.setAttribute('id', 'modelEntity');
      entity.setAttribute('gltf-model', '#model');
      entity.setAttribute('scale', '10 10 10');
      entity.setAttribute('cubemap-static', '')
      entity.setAttribute('shadow', 'receive: false');
      entity.setAttribute('animation-mixer', {
        clip: 'none',
        loop: 'once',
        clampWhenFinished: 'true',
      });
      entity.setAttribute(modelRef, '');
      modelContainer?.appendChild(entity);

      const buttonClickHandle = (buttonName: string) => {
        DisableButtons();
        if (!buttonHandleEventRef.current) { console.error('something wrong') };
        if (buttonHandleEventRef.current) {
          buttonHandleEventRef.current.next(buttonName);
        }
      }

      return () => {
        subscription.unsubscribe();
      }
    }
  });

  useEffect(() => {
    if (onButtonClick && buttonHandleEventRef.current) {
      const subscription = buttonHandleEventRef.current.subscribe((data: unknown) => {
        onButtonClick(data);
      })

      return () => { subscription.unsubscribe() }
    }
  }, [onButtonClick])

  useEffect(() => {
    // recenter event
    if (recenterEvent) {
      const aFrameComponent = aFrameComponentRef.current;

      const subscription = recenterEvent.subscribe(() => {

        if (aFrameComponent !== null && aFrameComponent !== undefined) { aFrameComponent.emit('recenter') }
        else { console.log('A-Frame scene not defined') }

      })

      return () => { subscription.unsubscribe() }
    }

  }, [recenterEvent]);

  useEffect(() => {
    if (beardStyleEvent) {

      const subscription = beardStyleEvent.pipe(
        skip(1),
        filter(() => beardStyles.length > 0)
      ).subscribe(isUsingFace => {
        const aFrameComponent = aFrameComponentRef.current;

        if (isUsingFace) {
          document.querySelector('#modelContainer')?.setAttribute('visible', 'false')
          if (!!aFrameComponent) {
            aFrameComponent.renderer.outputEncoding = THREE.LinearEncoding;
          }
          aFrameComponent?.removeAttribute('xrweb')
          aFrameComponent?.setAttribute('xrface', {
            mirroredDisplay: true,
            cameraDirection: 'front',
          })
          aFrameComponent?.insertAdjacentHTML('beforeend',
            ` 
                <xrextras-faceanchor id="face-effect">
                  <xrextras-face-mesh id="face-mesh" material-resource="#paint-${beardStyles[0].id}"></xrextras-face-mesh>
                </xrextras-faceanchor>

                <xrextras-capture-button capture-mode="photo"></xrextras-capture-button>
        
                <!-- configure capture settings -->
                <xrextras-capture-config
                  file-name-prefix="braun-image-"
                  request-mic="manual"
                ></xrextras-capture-config>
        
                <!-- add capture preview -->
                <xrextras-capture-preview></xrextras-capture-preview>
          
            `);


          // add camera button else where
          setTimeout(() => {
            const beardInteractionARea = document.querySelector('#beard-content-drawer');
            if (!!beardInteractionARea) {
              const offsetBottom = beardInteractionARea.getBoundingClientRect().height;
              const recorderButton = document.querySelector('#recorder.recorder-container');
              if (!!recorderButton) {
                recorderButton.setAttribute('style', `bottom: ${offsetBottom + 20}px !important;z-index: 3000;`);
              }
            }
          }, 50)
        }
        else {
          document.querySelector('#modelContainer')?.setAttribute('visible', 'true')
          if (!!aFrameComponent) {
            aFrameComponent.renderer.outputEncoding = THREE.sRGBEncoding;
          }
          const faceAnchor = document.querySelector('xrextras-faceanchor')
          faceAnchor?.parentNode?.removeChild(faceAnchor)
          aFrameComponent?.removeAttribute('xrface')
          aFrameComponent?.setAttribute('xrweb', '')

          //remove screenshot button 
          const captureButton = document.querySelector('xrextras-capture-button');
          captureButton?.parentNode?.removeChild(captureButton);
          const captureButtonConfig = document.querySelector('xrextras-capture-config');
          captureButtonConfig?.parentNode?.removeChild(captureButtonConfig);
          const captureButtonPrev = document.querySelector('xrextras-capture-preview');
          captureButtonPrev?.parentNode?.removeChild(captureButtonPrev);

        }

      });

      return () => subscription.unsubscribe();

    }
  }, [beardStyleEvent, beardStyles])

  useEffect(() => {
    if (switchBeardStyleEvent) {
      const subscription = switchBeardStyleEvent.subscribe(beardStyleId => {
        const aFrameComponent = aFrameComponentRef.current;
        const faceMesh = aFrameComponent?.querySelector("xrextras-face-mesh#face-mesh");
        // FIXME
        if (!!faceMesh) {
          faceMesh.setAttribute('material-resource', `#paint-${beardStyleId}`);
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [switchBeardStyleEvent])

  useEffect(() => {
    const subscription = buttonHandleEventRef.current.subscribe((buttonName: string) => {
      // check if a selected button should trigger model overlay effect
      const btnData = buttonsList?.find(btn => btn.buttonName === buttonName);
      const btnName = btnData?.buttonName || '';
      const arModelOverlayPlaytime = btnData?.arModelOverlayPlaytime || 0;
      const chromaColor = btnData?.arModelOverlayBgColor || '0 0 0';
      if (!!btnName) {
        const videoEl = document.querySelector(`#overlayVideo${btnName}`) as HTMLVideoElement;
        if (!!videoEl) {
          setTimeout(() => {
            try {
              videoEl.play();
              document.querySelector('#overlayVideoMesh')?.setAttribute('xrextras-play-video', `video: #overlayVideo${btnName}`);
              document.querySelector('#overlayVideoMesh')?.setAttribute('material', `shader: chromakey; src: #overlayVideo${btnName}; color: ${chromaColor}; side: double; depthTest: true;`);
              document.querySelector('#overlayVideoMesh')?.setAttribute('visible', 'true'); //disable water video    
            } catch (ex) {
              console.error(ex);
            }
          }, arModelOverlayPlaytime)
        }
      }
    })

    return () => { subscription.unsubscribe(); }
  }, [buttonsList])

  useEffect(() => {
    if (buttonToggleEvent) {
      const subscription = buttonToggleEvent.subscribe(buttonName => {
        if (!buttonName) {
          EnableButtons();

          // disable all overlay as well when button should be displayed since this means a specific button animation ends
          document.querySelector('#overlayVideoMesh')?.setAttribute('visible', 'false');
          const videoEls = document.querySelectorAll<HTMLVideoElement>('.alpha-video');
          videoEls.forEach((el: HTMLVideoElement) => {
            el.pause();
            el.currentTime = 0;
          });
        } else {
          DisableButtons();
        }
      })

      return () => { subscription.unsubscribe(); }
    }
  }, [buttonToggleEvent])

  return (
    <>
      <Box style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: window.innerHeight,
        width: window.innerWidth
      }}>
        {arTemplateMemo}
      </Box>
    </>
  )
});

const DISABLE_IMAGE_TARGETS: unknown[] = [];

export { AScene, DISABLE_IMAGE_TARGETS }
