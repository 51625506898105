/* eslint-disable no-throw-literal */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-array-constructor */
//
// Ported to JavaScript by Lazar Laszlo 2011
//
// lazarsoft@gmail.com, www.lazarsoft.info
//
//

//
// Copyright 2007 ZXing authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {qrcode} from './qrcode'

function AlignmentPattern(posX, posY, estimatedModuleSize) {
  this.x = posX
  this.y = posY
  this.count = 1
  this.estimatedModuleSize = estimatedModuleSize

  this.__defineGetter__('EstimatedModuleSize', function () {
    return this.estimatedModuleSize
  })
  this.__defineGetter__('Count', function () {
    return this.count
  })
  this.__defineGetter__('X', function () {
    return Math.floor(this.x)
  })
  this.__defineGetter__('Y', function () {
    return Math.floor(this.y)
  })
  this.incrementCount = function () {
    this.count++
  }
  this.aboutEquals = function (moduleSize, i, j) {
    if (Math.abs(i - this.y) <= moduleSize && Math.abs(j - this.x) <= moduleSize) {
      const moduleSizeDiff = Math.abs(moduleSize - this.estimatedModuleSize)
      return moduleSizeDiff <= 1.0 || moduleSizeDiff / this.estimatedModuleSize <= 1.0
    }
    return false
  }
}

function AlignmentPatternFinder(image, startX, startY, width, height, moduleSize, resultPointCallback) {
  this.image = image
  this.possibleCenters = new Array()
  this.startX = startX
  this.startY = startY
  this.width = width
  this.height = height
  this.moduleSize = moduleSize
  this.crossCheckStateCount = new Array(0, 0, 0)
  this.resultPointCallback = resultPointCallback

  this.centerFromEnd = function (stateCount, end) {
    return (end - stateCount[2]) - stateCount[1] / 2.0
  }
  this.foundPatternCross = function (stateCount) {
    const {moduleSize} = this
    const maxVariance = moduleSize / 2.0
    for (let i = 0; i < 3; i++) {
      if (Math.abs(moduleSize - stateCount[i]) >= maxVariance) {
        return false
      }
    }
    return true
  }

  this.crossCheckVertical = function (startI, centerJ, maxCount, originalStateCountTotal) {
    const {image} = this

    const maxI = qrcode.height
    const stateCount = this.crossCheckStateCount
    stateCount[0] = 0
    stateCount[1] = 0
    stateCount[2] = 0

    // Start counting up from center
    let i = startI
    while (i >= 0 && image[centerJ + i * qrcode.width] && stateCount[1] <= maxCount) {
      stateCount[1]++
      i--
    }
    // If already too many modules in this state or ran off the edge:
    if (i < 0 || stateCount[1] > maxCount) {
      return NaN
    }
    while (i >= 0 && !image[centerJ + i * qrcode.width] && stateCount[0] <= maxCount) {
      stateCount[0]++
      i--
    }
    if (stateCount[0] > maxCount) {
      return NaN
    }

    // Now also count down from center
    i = startI + 1
    while (i < maxI && image[centerJ + i * qrcode.width] && stateCount[1] <= maxCount) {
      stateCount[1]++
      i++
    }
    if (i == maxI || stateCount[1] > maxCount) {
      return NaN
    }
    while (i < maxI && !image[centerJ + i * qrcode.width] && stateCount[2] <= maxCount) {
      stateCount[2]++
      i++
    }
    if (stateCount[2] > maxCount) {
      return NaN
    }

    const stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2]
    if (5 * Math.abs(stateCountTotal - originalStateCountTotal) >= 2 * originalStateCountTotal) {
      return NaN
    }

    return this.foundPatternCross(stateCount) ? this.centerFromEnd(stateCount, i) : NaN
  }

  this.handlePossibleCenter = function (stateCount, i, j) {
    const stateCountTotal = stateCount[0] + stateCount[1] + stateCount[2]
    const centerJ = this.centerFromEnd(stateCount, j)
    const centerI = this.crossCheckVertical(i, Math.floor(centerJ), 2 * stateCount[1], stateCountTotal)
    if (!isNaN(centerI)) {
      const estimatedModuleSize = (stateCount[0] + stateCount[1] + stateCount[2]) / 3.0
      const max = this.possibleCenters.length
      for (let index = 0; index < max; index++) {
        const center = this.possibleCenters[index]
        // Look for about the same center and module size:
        if (center.aboutEquals(estimatedModuleSize, centerI, centerJ)) {
          return new AlignmentPattern(centerJ, centerI, estimatedModuleSize)
        }
      }
      // Hadn't found this before; save it
      const point = new AlignmentPattern(centerJ, centerI, estimatedModuleSize)
      this.possibleCenters.push(point)
      if (this.resultPointCallback != null) {
        this.resultPointCallback.foundPossibleResultPoint(point)
      }
    }
    return null
  }

  this.find = function () {
    const {startX} = this
    const {height} = this
    const maxJ = startX + width
    const middleI = startY + (height >> 1)
    // We are looking for black/white/black modules in 1:1:1 ratio;
    // this tracks the number of black/white/black modules seen so far
    const stateCount = new Array(0, 0, 0)
    for (let iGen = 0; iGen < height; iGen++) {
      // Search from middle outwards
      const i = middleI + ((iGen & 0x01) == 0 ? ((iGen + 1) >> 1) : -((iGen + 1) >> 1))
      stateCount[0] = 0
      stateCount[1] = 0
      stateCount[2] = 0
      let j = startX
      // Burn off leading white pixels before anything else; if we start in the middle of
      // a white run, it doesn't make sense to count its length, since we don't know if the
      // white run continued to the left of the start point
      while (j < maxJ && !image[j + qrcode.width * i]) {
        j++
      }
      let currentState = 0
      while (j < maxJ) {
        if (image[j + i * qrcode.width]) {
          // Black pixel
          if (currentState == 1) {
            // Counting black pixels
            stateCount[currentState]++
          } else {
            // Counting white pixels
            if (currentState == 2) {
              // A winner?
              if (this.foundPatternCross(stateCount)) {
                // Yes
                var confirmed = this.handlePossibleCenter(stateCount, i, j)
                if (confirmed != null) {
                  return confirmed
                }
              }
              stateCount[0] = stateCount[2]
              stateCount[1] = 1
              stateCount[2] = 0
              currentState = 1
            } else {
              stateCount[++currentState]++
            }
          }
        } else {
          // White pixel
          if (currentState == 1) {
            // Counting black pixels
            currentState++
          }
          stateCount[currentState]++
        }
        j++
      }
      if (this.foundPatternCross(stateCount)) {
        var confirmed = this.handlePossibleCenter(stateCount, i, maxJ)
        if (confirmed != null) {
          return confirmed
        }
      }
    }

    // Hmm, nothing we saw was observed and confirmed twice. If we had
    // any guess at all, return it.
    if (!(this.possibleCenters.length == 0)) {
      return this.possibleCenters[0]
    }

    throw 'Couldn\'t find enough alignment patterns'
  }
}

export {AlignmentPattern, AlignmentPatternFinder}