import React from 'react';
import {
  Route,
  Switch
} from "react-router-dom";
import { AppHeader, LoginPage } from '../containers';

import routeMaps, { AppPages } from './routeMap';
import { ThemeProvider } from '@emotion/react';
import { Container, CssBaseline, Toolbar } from '@mui/material';
import PrivateRoute from './PrivateRoute';
import createAppTheme from '../core/theme';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../core/declarations/enum';
import { IQRCodeData } from '../core/declarations/app';
import CookieConsent from 'src/components/CookieConsent';

const AppRouter = () => {
  const queryClient = useQueryClient();
  const qrCodeData = queryClient.getQueryData(QueryKeys.qrCode) as IQRCodeData;
  const palette = qrCodeData?.palette;
  const brandName = qrCodeData?.brandName;
  const logo = qrCodeData?.logo;
  const theme = createAppTheme(palette);

  return (
    <ThemeProvider theme={theme}>
      {/* Css normalise */}
      <CssBaseline />

      <Container maxWidth="mobileLg" disableGutters sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>

        {/* Header */}
        <AppHeader brandName={brandName} logo={logo} />
        <Toolbar />
        <Switch>
          {routeMaps.map((route, idx) => {
            return (
              <PrivateRoute
                key={`route-${idx}`}
                path={route.path}
                exact={route.exact}
                component={route.component} />
            )
          })}
          {
            <Route path={AppPages.LoginPage} component={LoginPage} />
          }
        </Switch>

        {/* Cookie Consent */}
        <CookieConsent />
      </Container>
    </ThemeProvider >

  );
}

export default AppRouter;
