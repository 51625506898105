import { ARPage, HomePage, ScanPage, ProductFinder } from "../containers";
import { IAppRoute } from "../core/declarations/app";

export const AppPages = {
  InitialPage: '/initialize',
  LoginPage: '/login',
  HomePage: '/',
  StorePage: '/store',
  ARPage: '/ar-page',
  ScanPage: '/scan-page',
  ProductFinderPage: '/product-finder'
};

const routeMaps: IAppRoute[] = [
  {
    path: AppPages.HomePage,
    exact: true,
    component: HomePage
  },
  {
    path: AppPages.ScanPage,
    component: ScanPage
  },
  {
    path: AppPages.ARPage,
    component: ARPage
  },
  {
    path: AppPages.ProductFinderPage,
    component: ProductFinder
  }
];

export default routeMaps;
